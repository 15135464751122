export default {
    // Logger: {
    //   LOG_LEVEL: 'ERROR'
    // },
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: "us-west-2:74054986-283a-49ee-83ca-42f838e54c0f",
        // REQUIRED - Amazon Cognito Region
        region: "us-west-2",
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: "us-west-2_wxKoPlxxr",
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: "3ks7d0qm9esnmheo5nm3s6i1nf",
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
        // Hosted UI Auth
        oauth: {
            domain: "gn-showcase-apps.auth.us-west-2.amazoncognito.com",
            scope: [
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ],
            redirectSignIn: "https://advanceddiscovery.gracenote.com/login",
            redirectSignOut: "https://advanceddiscovery.gracenote.com",
            responseType: "token"
        }
    },
    Storage: {
        bucket: "gnsfshowcaseapps-userfiles-mobilehub-1452162049", // REQUIRED -  Amazon S3 bucket
        region: "us-west-2" // OPTIONAL -  Amazon service region
    }
};
